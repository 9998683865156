import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'


class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Redesign Web"
                    meta={[
                        { name: 'description', content: 'Web Development and Website ReDesign, in many platforms, as Shopify, Square, Wordpress, and others. Website customization, e-commerce integration and online advertisement.  '},
                        { name: 'keywords', content: 'design, development, ecommerce, redesign' },
                    ]}
                >

                    {/* <!-- Linkedin --> */}
                    <meta property="og:type" content="website" prefix="og: http://ogp.me/ns#" />
                    <meta property="og:url" content="https://redesignweb.netlify.app/" />
                    <meta property="og:title" content="Web Development and Design" />
                    <meta property="og:description" content="Resposive &amp; attractive websites design &amp; develepoment. Optimized eCommerce stores on Shopify, Squareup and others." />
                    <meta name =" image" property="og:image" prefix="og: http://ogp.me/ns#" content= "https://redesignweb.netlify.app/home.png"   />

                    <meta property="og:image:width" content="400" />
                    <meta property="og:image:height" content="50" />
                    <meta name="author" content="Katiuscia Novaes de Sa"></meta>
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${pic01})`}}>
                            <header className="major">
                                <h3>Web Design &amp; Development</h3>
                                <p>Responsive, personalized and Professional website for your company</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3>Content Management Systems</h3>
                                <p>Use of CM systems to offer simplicity, flexibility, scalability, and a cost-effective solution</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3>Ecommerce Website Design </h3>
                                <p>Planning and creating a high quality virtual store with e-commerce platforms</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>Digital Marketing</h3>
                                <p>Planning and creating Marketing and Sales strategies for your company.</p>
                            </header>
    
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                            <h3>Development</h3>
                                <p>Design, develop and manage high impact websites that generate leads, sell products and tell stories</p>
                    
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic06})`}}>
                            <header className="major">
                            <h3>Website Improvement</h3>
                                <p>The redesign of the website process involves upgrading content, refreshing templates and optimizing navigation for better conversions and site efficiency.</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Projects</h2>
                            </header>
                            <p> With a passion for innovation and creativity, and in build dynamic e-commerce stores as well as enhancing their functionality.</p>
                            <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex